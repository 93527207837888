//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABYRlqBpCW0-lyoIolf5oC5A1Icu9yTR4",
  authDomain: "cockroach-d3408.firebaseapp.com",
  projectId: "cockroach-d3408",
  storageBucket: "cockroach-d3408.firebasestorage.app",
  messagingSenderId: "41221550586",
  appId: "1:41221550586:web:0c9393e2c98b0c7ab8f443",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
